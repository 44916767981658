import {
  CLEAR_FETCH_ENTITY_VIDEO_AD_STATES,
  FETCH_ENTITY_VIDEO_AD_FAILURE,
  FETCH_ENTITY_VIDEO_AD_SUCCESS,
  REQUEST_ENTITY_VIDEO_FETCH_AD
} from "./entity.video.type";

export const requestFetchEntityVideoAd = () => {
  return {
    type: REQUEST_ENTITY_VIDEO_FETCH_AD
  };
};

export const fetchEntityVideoAdSuccess = (response) => {
  return {
    type: FETCH_ENTITY_VIDEO_AD_SUCCESS,
    payload: response
  };
};

export const fetchEntityVideoAdFailure = (error) => {
  return {
    type: FETCH_ENTITY_VIDEO_AD_FAILURE,
    payload: error
  };
};

export const clearFetchEntityVideoAdState = () => {
  return {
    type: CLEAR_FETCH_ENTITY_VIDEO_AD_STATES
  };
};
