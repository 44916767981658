import {
  CLEAR_FETCH_ENTITY_VIDEO_AD_STATES,
  FETCH_ENTITY_VIDEO_AD_FAILURE,
  FETCH_ENTITY_VIDEO_AD_SUCCESS,
  REQUEST_ENTITY_VIDEO_FETCH_AD
} from "./entity.video.type";

let initialState = {
  reload: false,
  response: {},
  error: null,
  loading: false
};

const fetchEntityVideoAdReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ENTITY_VIDEO_FETCH_AD:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ENTITY_VIDEO_AD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false
      };

    case FETCH_ENTITY_VIDEO_AD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false
      };

    case CLEAR_FETCH_ENTITY_VIDEO_AD_STATES:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false
      };

    default:
      return state;
  }
};

export default fetchEntityVideoAdReducer;
