import {
  Box,
  CardMedia,
  Grid,
  Link,
  Skeleton,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import ButtonAccent from "../../../common/button-accent/ButtonAccent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  createVideoPromise,
  getAllVideosInEntity
} from "../../../../services/video.service";
import { isEmpty } from "lodash";
import ReactPlayer from "react-player";
import { Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../../utils/constant";

const SectionEventVideos = () => {
  const params = useParams();

  const videoState = useSelector((state) => state.videoAd);
  const videoData = videoState?.response?.data;
  const { entityId } = params;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      embeded_link: "",
      title: "",
      description: ""
    }
  });

  useEffect(() => {
    if (isEmpty(videoState.response)) {
      dispatch(getAllVideosInEntity(entityId));
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const res = await createVideoPromise(entityId, data);
      if (res.error_data) {
        toast.error(
          res.error_data ? res.error_data.message : STD_ERROR_MESSAGE
        );
        return;
      }
      toast.success("Added link successfully");
      reset();
      dispatch(getAllVideosInEntity(entityId));
    } catch (e) {
      toast.error(STD_ERROR_MESSAGE);
    }
  };

  return (
    <main
      style={{ maxWidth: "850px", marginLeft: "auto", marginRight: "auto" }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5 style={{ marginBottom: "1rem" }}>Add Link</h5>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              type="text"
              fullWidth
              {...register("embeded_link", { required: true })}
              error={!!errors.embeded_link}
              helperText={errors.embeded_link ? "Required" : ""}
            />
          </Grid>
        </Grid>
        <h5 style={{ marginTop: "1rem" }}>Add Title</h5>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              type="text"
              fullWidth
              {...register("title", { required: true })}
              error={!!errors.title}
              helperText={errors.title ? "Required" : ""}
            />
          </Grid>
        </Grid>
        <h5 style={{ marginTop: "1rem" }}>Add Description</h5>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField type="text" {...register("description")} fullWidth />
          </Grid>
        </Grid>

        <Grid item xs={2} sx={{ ml: 80, mt: 2 }}>
          <ButtonAccent label="Add" type="submit">
            Add
          </ButtonAccent>
        </Grid>
      </form>
      <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "20px" }}>
        Uploaded File
      </h2>
      {videoData ? (
        <Grid container spacing={2} sx={{ p: 2 }}>
          {videoData.map((video, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia>
                    <Box>
                      <ReactPlayer
                        url={video.embeded_link}
                        width="100%"
                        height="150px"
                        controls
                      />
                    </Box>
                  </CardMedia>
                </Card>
                <Typography variant="subtitle1" gutterBottom>
                  {video.title}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body2" color="text.secondary">
                    {video.description}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Skeleton variant="rectangular" width={210} height={118} />
      )}
    </main>
  );
};

export default SectionEventVideos;
