import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVideosInEntity } from "../../../services/video.service";
import ReactPlayer from "react-player";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";

const UserVideo = ({ entityId }) => {
  const dispatch = useDispatch();

  const isMobileOrTablet = useMediaQuery("(max-width: 599px)");
  const minHeight = isMobileOrTablet ? 56 + 56 + 40 : 64 + 56 + 40;

  const videoState = useSelector((state) => state.videoAd);
  const videoData = videoState?.response?.data;

  useEffect(() => {
    if (isEmpty(videoState.response)) {
      dispatch(getAllVideosInEntity(entityId));
    }
  }, []);

  return (
    <div style={{ minHeight: `calc(100vh - ${minHeight}px)` }}>
      {videoData ? (
        <Grid container spacing={2} sx={{ p: { xs: 2, md: 10 } }}>
          {videoData.map((video, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card>
                <CardMedia>
                  <ReactPlayer
                    url={video.embeded_link}
                    width="100%"
                    height="210px"
                    controls
                  />
                </CardMedia>
              </Card>
              <Typography variant="subtitle1" gutterBottom>
                {video.title}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2" color="text.secondary">
                  {video.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Skeleton variant="rectangular" width={210} height={118} />
      )}
    </div>
  );
};

export default UserVideo;
