import {
  CLEAR_WHATSAPP_LINK,
  FETCH_WHATSAPP_LINK_FAILURE,
  FETCH_WHATSAPP_LINK_SUCCESS,
  REQUEST_WHATSAPP_LINK
} from "./whatsapp.types";

export const requestFetchWhatsappLink = () => {
  return {
    type: REQUEST_WHATSAPP_LINK
  };
};

export const fetchWhatsappLinkSuccess = (response) => {
  return {
    type: FETCH_WHATSAPP_LINK_SUCCESS,
    payload: response
  };
};

export const fetchWhatsappLinkFailure = (error) => {
  return {
    type: FETCH_WHATSAPP_LINK_FAILURE,
    payload: error
  };
};

export const clearWhatsappLinkStates = () => {
  return {
    type: CLEAR_WHATSAPP_LINK
  };
};
