import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cancelIcon from "../../../assets/icon/cancel.svg";
import { getPartnerContactNo } from "../../../services/addInfo.sevice";
import "./AddInfo.css";
import "./InfoQuery.css";
import { Box, Button, Typography } from "@mui/material";

const AddInfoPopup = (props) => {
  const { entityId } = props;

  const dispatch = useDispatch();

  const contactInfoState = useSelector((state) => state.whatssAppLinkAd);
  const contactInfoData = contactInfoState?.response?.data;

  useEffect(() => {
    if (contactInfoData == null) {
      dispatch(getPartnerContactNo(entityId));
    }
  });

  const message = "Hey there!";

  const { handleClose } = props;

  // Encode the message to make it URL-safe
  const encodedMessage = encodeURIComponent(message);

  // Construct the wa.me link
  const waLink = `https://wa.me/${contactInfoData}?text=${encodedMessage}`;

  return (
    <div className="addInfoPopup">
      <div>
        <div className="addInfoBox">
          <img
            className="close-icon"
            onClick={handleClose}
            src={cancelIcon}
            alt=""
          />
          <div className="infoBox" style={{ padding: "1rem" }}>
            <div className="mt-4 d-flex justify-content-center">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Typography variant="h4">Get in touch!</Typography>
                <a href={waLink} target="_blank" rel="noopener noreferrer">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <WhatsAppIcon
                      fontSize="large"
                      color="success"
                      sx={{ marginLeft: "40%" }}
                    />
                    <Button variant="contained" color="success">
                      Message us
                    </Button>
                  </Box>
                </a>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInfoPopup;
