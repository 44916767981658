import {
  fetchWhatsappLinkFailure,
  fetchWhatsappLinkSuccess,
  requestFetchWhatsappLink
} from "../redux/whatsapp-link/whatsapp.action";
import ProtectedWebClient from "../utils/protected-web-client";

export const addInformationToEnquiryPromise = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/enquiry`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const getPartnerContactNo = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchWhatsappLink());
      const response = await ProtectedWebClient.get(
        `/entity/${entityId}/contactInfo`
      );
      dispatch(fetchWhatsappLinkSuccess(response?.data));
    } catch (error) {
      dispatch(fetchWhatsappLinkFailure(error?.response));
    }
  };
};
