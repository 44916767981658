import {
  CLEAR_WHATSAPP_LINK,
  FETCH_WHATSAPP_LINK_FAILURE,
  FETCH_WHATSAPP_LINK_SUCCESS,
  REQUEST_WHATSAPP_LINK
} from "./whatsapp.types";

let initialState = {
  relaod: false,
  response: {},
  error: null,
  loading: false
};

const fetchWhatsappLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_WHATSAPP_LINK:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_WHATSAPP_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        reload: false
      };

    case FETCH_WHATSAPP_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        reload: false
      };

    case CLEAR_WHATSAPP_LINK:
      return {
        ...state,
        loading: false,
        response: {},
        error: null,
        reload: false
      };

    default:
      return state;
  }
};

export default fetchWhatsappLinkReducer;
