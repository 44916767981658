import {
  fetchEntityVideoAdFailure,
  fetchEntityVideoAdSuccess,
  requestFetchEntityVideoAd
} from "../redux/entity-video/entity.video.action";
import ProtectedWebClient from "../utils/protected-web-client";

export const getAllVideosInEntity = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch(requestFetchEntityVideoAd());
      const response = await ProtectedWebClient.get(
        `/entity/${entityId}/video`
      );
      dispatch(fetchEntityVideoAdSuccess(response?.data));
    } catch (error) {
      dispatch(fetchEntityVideoAdFailure(error?.response?.data));
    }
  };
};

export const createVideoPromise = (entityId, payload) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.post(`/entity/${entityId}/video`, payload)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};

export const updateVideoByIdPromise = (entityId, videoId) => {
  return new Promise((resolve, reject) => {
    ProtectedWebClient.put(`/entity/${entityId}/video/${videoId}`)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error?.data);
      });
  });
};
